@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Just+Another+Hand&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Playwrite+CU:wght@100..400&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

/* Only necessary with horizontal scrolling */

/* html.lenis{height:auto}.lenis.lenis-smooth{scroll-behavior:auto}.lenis.lenis-smooth [data-lenis-prevent]{overscroll-behavior:contain}.lenis.lenis-stopped{overflow:hidden}.lenis.lenis-scrolling iframe{pointer-events:none}
html[data-scroll-orientation="horizontal"] {
  body {
      width: fit-content;
  }
  [data-scroll-container] {
      display: flex;
  }
} */
*{
  font-family: "Lexend", sans-serif;
  font-size: 14px;
  /* cursor: none !important; */
  
}
.Anton{
  font-family: "Anton", sans-serif !important;
}
.Oswald{
  font-family: "Oswald", sans-serif !important;
}
.opanSans{
  font-family: "Open Sans", system-ui !important;
}

.cubaFamilly{
  font-family: "Playwrite CU" !important;
}

.montSerrat{
  font-family: "Montserrat", sans-serif !important;
}
.justAnotherHand{
  font-family: "Just Another Hand", cursive !important;
}


.rubikFont{
  font-family: "Rubik", serif;
}


/* html{
  cursor: url("../public/images/mouse.svg"), ns-resize;
} */

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: 'Lato', sans-serif !important;
  overscroll-behavior: none;
  scroll-behavior: smooth;
  background-color: black;
  
  
 
  
}

iframe{
  width: 100%;
  height: 100vh;
}



/* html.lenis{height:auto}.lenis.lenis-smooth{scroll-behavior:auto}.lenis.lenis-smooth [data-lenis-prevent]{overscroll-behavior:contain}.lenis.lenis-stopped{overflow:hidden}.lenis.lenis-scrolling iframe{pointer-events:none} */

/* body {
  overscroll-behavior: none;
  height: 100vh;
} */
.xSlider {
  overscroll-behavior: none;
  
  width: 200%;
  
  display: flex;
  flex-wrap: nowrap;

  
}






.gray {
  background-color: #777;
  background-image: radial-gradient(circle at 47% 14%, rgba(205, 205, 205,0.04) 0%, rgba(205, 205, 205,0.04) 43%,transparent 43%, transparent 100%),radial-gradient(circle at 35% 12%, rgba(215, 215, 215,0.04) 0%, rgba(215, 215, 215,0.04) 4%,transparent 4%, transparent 100%),radial-gradient(circle at 1% 35%, rgba(24, 24, 24,0.04) 0%, rgba(24, 24, 24,0.04) 37%,transparent 37%, transparent 100%),radial-gradient(circle at 21% 1%, rgba(0, 0, 0,0.04) 0%, rgba(0, 0, 0,0.04) 26%,transparent 26%, transparent 100%),radial-gradient(circle at 23% 82%, rgba(249, 249, 249,0.04) 0%, rgba(249, 249, 249,0.04) 60%,transparent 60%, transparent 100%),radial-gradient(circle at 11% 54%, rgba(251, 251, 251,0.04) 0%, rgba(251, 251, 251,0.04) 23%,transparent 23%, transparent 100%),radial-gradient(circle at 69% 68%, rgba(234, 234, 234,0.04) 0%, rgba(234, 234, 234,0.04) 10%,transparent 10%, transparent 100%),linear-gradient(90deg, #777,#606060);
}
.blue {
  background-color: #2c7ad2;
  background-image: radial-gradient(circle at 47% 14%, rgba(205, 205, 205,0.04) 0%, rgba(205, 205, 205,0.04) 43%,transparent 43%, transparent 100%),radial-gradient(circle at 35% 12%, rgba(215, 215, 215,0.04) 0%, rgba(215, 215, 215,0.04) 4%,transparent 4%, transparent 100%),radial-gradient(circle at 1% 35%, rgba(24, 24, 24,0.04) 0%, rgba(24, 24, 24,0.04) 37%,transparent 37%, transparent 100%),radial-gradient(circle at 21% 1%, rgba(0, 0, 0,0.04) 0%, rgba(0, 0, 0,0.04) 26%,transparent 26%, transparent 100%),radial-gradient(circle at 23% 82%, rgba(249, 249, 249,0.04) 0%, rgba(249, 249, 249,0.04) 60%,transparent 60%, transparent 100%),radial-gradient(circle at 11% 54%, rgba(251, 251, 251,0.04) 0%, rgba(251, 251, 251,0.04) 23%,transparent 23%, transparent 100%),radial-gradient(circle at 69% 68%, rgba(234, 234, 234,0.04) 0%, rgba(234, 234, 234,0.04) 10%,transparent 10%, transparent 100%),linear-gradient(90deg, #2c7ad2,#1568c6);
}
.orange {
  background-color: #e77614;
  background-image: radial-gradient(circle at 46% 40%, rgba(228, 228, 228,0.06) 0%, rgba(228, 228, 228,0.06) 13%,transparent 13%, transparent 100%),radial-gradient(circle at 11% 41%, rgba(198, 198, 198,0.06) 0%, rgba(198, 198, 198,0.06) 19%,transparent 19%, transparent 100%),radial-gradient(circle at 52% 23%, rgba(14, 14, 14,0.06) 0%, rgba(14, 14, 14,0.06) 69%,transparent 69%, transparent 100%),radial-gradient(circle at 13% 85%, rgba(148, 148, 148,0.06) 0%, rgba(148, 148, 148,0.06) 44%,transparent 44%, transparent 100%),radial-gradient(circle at 57% 74%, rgba(232, 232, 232,0.06) 0%, rgba(232, 232, 232,0.06) 21%,transparent 21%, transparent 100%),radial-gradient(circle at 59% 54%, rgba(39, 39, 39,0.06) 0%, rgba(39, 39, 39,0.06) 49%,transparent 49%, transparent 100%),radial-gradient(circle at 98% 38%, rgba(157, 157, 157,0.06) 0%, rgba(157, 157, 157,0.06) 24%,transparent 24%, transparent 100%),radial-gradient(circle at 8% 6%, rgba(60, 60, 60,0.06) 0%, rgba(60, 60, 60,0.06) 12%,transparent 12%, transparent 100%),linear-gradient(90deg, #ff7600, #ff7600);
}

.red {
  background-color: #c82736;
  background-image: radial-gradient(circle at 19% 90%, rgba(190, 190, 190,0.04) 0%, rgba(190, 190, 190,0.04) 17%,transparent 17%, transparent 100%),radial-gradient(circle at 73% 2%, rgba(78, 78, 78,0.04) 0%, rgba(78, 78, 78,0.04) 94%,transparent 94%, transparent 100%),radial-gradient(circle at 45% 2%, rgba(18, 18, 18,0.04) 0%, rgba(18, 18, 18,0.04) 55%,transparent 55%, transparent 100%),radial-gradient(circle at 76% 60%, rgba(110, 110, 110,0.04) 0%, rgba(110, 110, 110,0.04) 34%,transparent 34%, transparent 100%),radial-gradient(circle at 68% 56%, rgba(246, 246, 246,0.04) 0%, rgba(246, 246, 246,0.04) 16%,transparent 16%, transparent 100%),radial-gradient(circle at 71% 42%, rgba(156, 156, 156,0.04) 0%, rgba(156, 156, 156,0.04) 47%,transparent 47%, transparent 100%),radial-gradient(circle at 46% 82%, rgba(247, 247, 247,0.04) 0%, rgba(247, 247, 247,0.04) 39%,transparent 39%, transparent 100%),radial-gradient(circle at 50% 47%, rgba(209, 209, 209,0.04) 0%, rgba(209, 209, 209,0.04) 45%,transparent 45%, transparent 100%),linear-gradient(90deg, #e53949,#cc2232);
}

.purple {
  background-color: #8d3dae;
  background-image: radial-gradient(circle at 47% 14%, rgba(205, 205, 205,0.04) 0%, rgba(205, 205, 205,0.04) 43%,transparent 43%, transparent 100%),radial-gradient(circle at 35% 12%, rgba(215, 215, 215,0.04) 0%, rgba(215, 215, 215,0.04) 4%,transparent 4%, transparent 100%),radial-gradient(circle at 1% 35%, rgba(24, 24, 24,0.04) 0%, rgba(24, 24, 24,0.04) 37%,transparent 37%, transparent 100%),radial-gradient(circle at 21% 1%, rgba(0, 0, 0,0.04) 0%, rgba(0, 0, 0,0.04) 26%,transparent 26%, transparent 100%),radial-gradient(circle at 23% 82%, rgba(249, 249, 249,0.04) 0%, rgba(249, 249, 249,0.04) 60%,transparent 60%, transparent 100%),radial-gradient(circle at 11% 54%, rgba(251, 251, 251,0.04) 0%, rgba(251, 251, 251,0.04) 23%,transparent 23%, transparent 100%),radial-gradient(circle at 69% 68%, rgba(234, 234, 234,0.04) 0%, rgba(234, 234, 234,0.04) 10%,transparent 10%, transparent 100%),linear-gradient(90deg, #8d3dae,#8d3dae);
}
.green {
  background-color: #28a92b;
  background-image: radial-gradient(circle at 46% 40%, rgba(228, 228, 228,0.06) 0%, rgba(228, 228, 228,0.06) 13%,transparent 13%, transparent 100%),radial-gradient(circle at 11% 41%, rgba(198, 198, 198,0.06) 0%, rgba(198, 198, 198,0.06) 19%,transparent 19%, transparent 100%),radial-gradient(circle at 52% 23%, rgba(14, 14, 14,0.06) 0%, rgba(14, 14, 14,0.06) 69%,transparent 69%, transparent 100%),radial-gradient(circle at 13% 85%, rgba(148, 148, 148,0.06) 0%, rgba(148, 148, 148,0.06) 44%,transparent 44%, transparent 100%),radial-gradient(circle at 57% 74%, rgba(232, 232, 232,0.06) 0%, rgba(232, 232, 232,0.06) 21%,transparent 21%, transparent 100%),radial-gradient(circle at 59% 54%, rgba(39, 39, 39,0.06) 0%, rgba(39, 39, 39,0.06) 49%,transparent 49%, transparent 100%),radial-gradient(circle at 98% 38%, rgba(157, 157, 157,0.06) 0%, rgba(157, 157, 157,0.06) 24%,transparent 24%, transparent 100%),radial-gradient(circle at 8% 6%, rgba(60, 60, 60,0.06) 0%, rgba(60, 60, 60,0.06) 12%,transparent 12%, transparent 100%),linear-gradient(90deg, #28a92b, #10a614);
}


.videoSecitonContainer {
  overscroll-behavior: none;
  
  width: 200%;
  
  display: flex;
  flex-wrap: nowrap;

  
}
.videoPanel {
	width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ;
  position: relative;
  
  
  
}






.relative {
  position: relative;
}

.padding-1rem {
  padding: 1rem;
}

button {
  padding: 0.3rem;
}

.circular-progress-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0; /* remove white-space below svg */
}

.circular-progress-container > h2 {
  position: absolute;
}

.circular-progress-svg-container > svg {
  transform: rotate(-90deg);
}


.lCircleTransform{
  transform: rotate(var(--rotate));
}












/* width */
::-webkit-scrollbar {
  width: 5px;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: black; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: white; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ff0000; 
}





:root {
  --stroke-dashoffset-to: 465;
}


@keyframes slidein {
  from {
    stroke-dashoffset:465
  }

  to {
    stroke-dashoffset:var( --stroke-dashoffset-to)
  }
}



.animated-element {

  animation: slidein 2s ease-in-out;
}





.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}


.lg-react-element {
  display: flex !important;
}




/* .ball {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  border: 3px solid rgb(69, 40, 0);
  border-radius: 50%;
  pointer-events: none;
  z-index: 50;
} */



.z {
  width: 10px;
  height: 10px;
  position: fixed;
  top: 0;
  left: 0;
  border: 3px solid rgb(69, 40, 0);
  border-radius: 50%;
  pointer-events: none;
  z-index: 50;
}

/* .kl{
  width: 20px;
  height: 20px;
  position: fixed;
  top: 0;
  left: 0;
  border: 3px solid rgb(69, 40, 0);
  border-radius: 50%;
  pointer-events: none;
  z-index: 50;
}  */






.item {
  width: 20px;
  height: 20px;
  position: fixed;
  transform: scale(0);
  z-index: 100;
  
}




.input-field::placeholder {
  color: #d3d3d3;
  font-size: 12px;
}



.customGradient-2{
  background: rgb(20,0,121);
background: -moz-linear-gradient(270deg, rgba(20,0,121,1) 0%, rgba(144,17,193,1) 63%, rgba(0,43,77,1) 100%);
background: -webkit-linear-gradient(270deg, rgba(20,0,121,1) 0%, rgba(144,17,193,1) 63%, rgba(0,43,77,1) 100%);
background: linear-gradient(270deg, rgba(20,0,121,1) 0%, rgba(144,17,193,1) 63%, rgba(0,43,77,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#140079",endColorstr="#002b4d",GradientType=1);
}


.customGradient-3{
  background: rgb(20,0,121);
background: -moz-linear-gradient(270deg, rgba(20,0,121,1) 0%, rgba(144,17,193,0.5466561624649859) 63%, rgba(0,142,255,1) 100%);
background: -webkit-linear-gradient(270deg, rgba(20,0,121,1) 0%, rgba(144,17,193,0.5466561624649859) 63%, rgba(0,142,255,1) 100%);
background: linear-gradient(270deg, rgba(20,0,121,1) 0%, rgba(144,17,193,0.5466561624649859) 63%, rgba(0,142,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#140079",endColorstr="#008eff",GradientType=1);
}